import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class PrivacyPolicy extends React.Component {

  render(){

    return(
      <Layout>

        <SEO title="Privacy Policy"/>
        <Link to="/" className="logo">Super!com</Link>
        
        <div className="text-page -all-width">
          <div className="center">

            <h2 className="text-page__title">Privacy Policy</h2>

            <p className="text-page__paragraph">
              Super.Com respects the data protection rights of users and complies with applicable data protection laws and regulations. By way of this privacy policy, we inform the public of the nature, scope, and purposes of the personal data we collect, use, and process, as well as of the rights to which users are entitled.
            </p>
            <p className="text-page__paragraph">
              Please read our privacy policy carefully to get a clear understanding of the way we handle your personal data on our website.
            </p>

            <p className="text-page__paragraph -title">
              1. General Provisions
            </p>
            <p className="text-page__paragraph">
              The primary purposes and lawful basis for processing your personal data is to take steps at your request prior to entering into contracts and to perform obligations under contracts effective between you and Super.Com. For example, we process data you submitted to us via the publishing form in order to handle your request.
            </p>
            <p className="text-page__paragraph">
              In this Privacy Policy, we use the definitions of the terms contained in the General Data Protection Regulation (GDPR). In the event that you feel uncertain about the exact meaning of some of the terms or provisions envisaged in this Privacy Policy, you may contact us by email (see Section 9).
            </p>
            <p className="text-page__paragraph -title">
              2.   Information We May Collect
            </p>
            <p className="text-page__paragraph">
              Note that the provision of data in a volume allowing us to uniquely and unambiguously identify you is not a requirement for visiting the website. However, we may collect and process certain personal information when you fill in the forms available on the website or communicate with us by e-mail. The scope and nature of this information is described below.
            </p>
            <p className="text-page__paragraph">
              When you fill in the forms (e.g., contact form, publishing form, fund form), we may collect and process the following data:
            </p>

            <ul className="text-page__list">
              <li>email address</li>
              <li>IP address</li>
              <li>name</li>
              <li>company you represent</li>
              <li>country and location</li>
              <li>preferred language</li>
              <li>other information that you shared with us.</li>
            </ul>

            <p className="text-page__paragraph">
              When you subscribe for out newsletter, we may collect and process the following data:
            </p>

              email address
              name.

            <p className="text-page__paragraph">
              When you contact us by email, we may collect and process information and data you provide over the course of communication.
            </p>
            <p className="text-page__paragraph">
              We do not collect, store, or process any specific categories of personal data, such as data regarding your racial or ethnic origin, political views, religion, philosophical beliefs, trade unions membership, genetic data, biometric data, or data pertaining to your health, sex life, or sexual orientation.
            </p>

            <p className="text-page__paragraph -title">
              3.  Cookies
            </p>
            <p className="text-page__paragraph">
              Cookies refer to electronic information that may be stored on the user's device and enables the website to remember the user’s actions and preferences over a period of time.
            </p>
            <p className="text-page__paragraph">
              Our websites may employ cookies in order to identify the user’s hardware and software (including their browser), customize their preferences, and keep a record of their actions on the website. We use the following categories of cookies:
            </p>

            <ul className="text-page__list">
              <li>Performance cookies, which allow us to count visits and traffic sources so we can measure and improve the performance of our site. These cookies do not store any personally identifiable information.</li>
              <li>Analytical cookies of third parties, which may also include web beacons and pixel tags. We may use third party website analytics tools (i.e., Google Analytics and Facebook pixel) that employ cookies of third parties to collect certain information, e.g. website address links, page views and browser types.</li>
            </ul>

            <p className="text-page__paragraph">
              Providers of the website analytics tools may have access to cookies. Please follow the links below for details of the privacy policies and opt out choices offered by third parties providing website analytics tools:
            </p>

            <ul className="text-page__list">
              <li>Facebook: <a href="https://www.facebook.com/policy.php" className="text-page__link">https://www.facebook.com/policy.php</a></li>
              <li>Google: <a href="https://policies.google.com/privacy" className="text-page__link">https://policies.google.com/privacy</a></li>
              <li>Cookies lifetime depends on the type of cookies and varies from 1 day up to 2 years. However, you may delete all the cookies at any time.</li>
            </ul>

            <p className="text-page__paragraph">
              Enabling cookies is not strictly required to use the primary functions of the website, but it will provide you with a better browsing experience. You may monitor, delete, or disable cookies by changing your browser settings at any time. However, disabling cookies may impair some aspects of the website’s functionality.
            </p>

            <p className="text-page__paragraph -title">
              4.   How We Use Information
            </p>
            <p className="text-page__paragraph">
              We may use the information that we collect from you in order to take steps at your request prior to entering into contracts and to perform obligations under contracts effective between you and Super.Com. This basis and compliance with applicable laws constitute a due and valid legal basis for the processing of your personal data for the following purposes:
            </p>

            <ul className="text-page__list">
              <li>to handle your requests, including applications for publishing and funding</li>
              <li>to negotiate terms and conditions of contracts;</li>
              <li>to perform contracts effective between you and Super.Com.</li>
            </ul>

            <p className="text-page__paragraph">
              We do not sell, trade, or share your personal data with third parties. However, such information may be transferred to the following third parties:
            </p>

            <ul className="text-page__list">
              <li>banks and payment service providers, but only to the extent required to perform contracts</li>
              <li>governmental bodies and law enforcement agencies, but only to the extent we are required to share and process such information pursuant to applicable law or court orders</li>
              <li>hosting providers, lessors of server equipment and devices, and security services providers, but only to the extent necessary to securely store information and data on the equipment and on the premises of such service providers without providing access to the information and data.</li>
            </ul>

            <p className="text-page__paragraph">
              For the purposes stated above, we may transfer information and data to the countries of the EEA (European Economic Area) or outside the EEA. In each case, we ensure that the level of protection guaranteed by applicable law is not undermined and statutory conditions of transfer laid down in applicable law are complied with.
            </p>

            <p className="text-page__paragraph -title">
              5.   Access, Correction, and Profile Updating
            </p>
            <p className="text-page__paragraph">
              You have the right to obtain a confirmation from us as to whether or not your personal data is being processed. In the event that we process your personal data, you may access such data, as well as the following information:
            </p>
            
            <ul className="text-page__list">
              <li>the purposes of said processing</li>
              <li>the categories of personal data concerned</li>
              <li>the recipients or categories of recipients to whom your personal data have been or will be disclosed, in particular recipients in other countries or international organizations</li>
              <li>the envisaged period for which the personal data will be stored, or the criteria used to determine this period</li>
              <li>the existence of the right to request the rectification or erasure of your personal data or the restriction of the processing of your personal data from us or to object to such processing</li>
              <li>the right to lodge a complaint with a supervisory authority</li>
              <li>in the event that the personal data was not collected from you, any available information as to its source</li>
              <li>the existence of automated decision-making, including profiling, meaningful information about the logic involved, and the significance and the envisaged consequences of such processing for you</li>
            </ul>

            <p className="text-page__paragraph">
              You have the right to correct, rectify, and update inaccurate or incomplete personal data. However, in some cases, in order to prevent unauthorized access to your account, we may request confirmation of the accuracy of the updated data.
            </p>

            <p className="text-page__paragraph -title">
              6.      Retention and Deletion
            </p>
            <p className="text-page__paragraph">
              We store and process your data until the termination of the contractual negotiations or relations between you and Super.Com. You reserve the right to request removal of your data at any time.
            </p>
            <p className="text-page__paragraph">
              We may retain the data collected before the receipt of the request for data removal for an additional period in order to be able to respond to the enquiries of financial institutions that processed your transactions, or enforce remedies available to us under applicable law, and comply with tax-related and bookkeeping obligations.
            </p>

            <p className="text-page__paragraph -title">
              7.      Privacy of Minors
            </p>
            <p className="text-page__paragraph">
              We do not specifically market our websites, games, or services to persons under the age of 18. We do not and will not knowingly collect or process personal data from any person under the age of 18 without the consent of a parent or guardian. Parents or guardians who are concerned about the transfer of personal data from their children may contact us by email or via the support service (see Section 9).
            </p>

            <p className="text-page__paragraph -title">
              8.      Amendments to the Policy
            </p>
            <p className="text-page__paragraph">
              We reserve the right to introduce amendments to this Policy at any time at our own discretion for various reasons, including, without limitation, to reflect changes in applicable law and implement amendments to our website, games, and services. The latest version of this Privacy Policy will always be available on the website.
            </p>
            <p className="text-page__paragraph">
              In the event that significant amendments are made that may limit your rights or impose additional obligations under this Privacy Policy, we will send you prior notice via email (if you provided us with your email address) and publish a new version of this Privacy Policy on the website at least ten days before its effective date.
            </p>
            <p className="text-page__paragraph">
              9.      Contact Information
            </p>
            <p className="text-page__paragraph">
              In this Privacy Policy, "we" refers to
            </p>
            <p className="text-page__paragraph">
              SUPER DOT COM LTD <br/>
              Address: 156a Burnt Oak Broadway Edgware Middlesex HA8 0AX, United Kingdom <br/>
              Email: legal@supergg.com
            </p>
            <p className="text-page__paragraph">
              In the event that you have any questions regarding this Privacy Policy, you may contact us by e-mail. In addition, you reserve the right to lodge a complaint to a supervisory authority.
            </p>
            <p className="text-page__paragraph">
              Date: November 28, 2019
            </p>

          </div>
        </div>

      </Layout>
    );

  }

}

export default PrivacyPolicy
